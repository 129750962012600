import { StatusChip } from '@/components/tables/bet/components'
import VideoPokerCard from '@/components/tables/casino/component/videoPokerCard'
import { getProfileSetting } from '@/firebase/utils'
import { Btcsv, LilbitLogo, LinkChain, Profile } from '@/icons/index'
import authSelector from '@/redux/selectors/auth'
import { formatDate } from '@/utils/dates'
import { successMsg } from '@/utils/toast'
import { Dialog, Transition } from '@headlessui/react'
import { DuplicateIcon, LinkIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import React, { Fragment, memo, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { resultColors } from '../../lib/poker'

const TxRenderer = ({ txName, tx }) => (
  <div className="flex flex-col items-start mt-2">
    <span className="text-sm font-semibold font-inter text-[#A6EF67]">
      {txName} Tx
    </span>
    <div className="flex space-x-3">
      <div className="max-w-[150px] sm:max-w-[230px] scroller">
        <span className="text-xs">{tx}</span>
      </div>
      <DuplicateIcon
        className="shrink-0 w-5 h-5 cursor-pointer"
        onClick={() => {
          window.navigator.clipboard.writeText(tx)
          successMsg(
            intl.formatMessage(
              {
                id: 'success.payoutTxCopied',
                defaultMessage: `PayoutTx ${tx} Successfully Copied`,
              },
              { tx },
            ),
          )
        }}
      />
      <div className="shrink-0">
        <a
          href={`https://whatsonchain.com/tx/${tx}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkIcon className="w-5 h-5 rotate-45 text-yellow-500" />
        </a>
      </div>
    </div>
  </div>
)

const SingleSlip = memo(
  ({ betData, topRounded, bottomRounded, slipBorder }) => {
    return (
      <div
        className={`bg-white/5 overflow-hidden ${
          topRounded ? 'rounded-t-xl' : ''
        } ${bottomRounded ? 'rounded-b-xl' : ''} ${
          slipBorder ? 'border-t-2 border-white/30 border-dotted' : ''
        }`}
      >
        <div className="bg-white/5 py-2 px-6 flex flex-row items-center justify-start gap-2 relative">
          <h4 className="overflow-ellipsis flex-1 text-left font-inter font-medium text-xs">
            Transaction Information
          </h4>
        </div>
        <div className="px-6 py-4">
          <div>
            <TxRenderer txName={'Bet'} tx={betData.sendTx} />

            {betData.getTx && (
              <TxRenderer txName={'Payout'} tx={betData.getTx} />
            )}
            <div className="flex mt-2 items-center justify-center space-x-1 text-sm font-medium font-inter text-white relative">
              <span>
                <StatusChip
                  status={betData.winningHand}
                  className={`bg-gray-200/20 ${
                    resultColors[betData.winningHand]
                  }`}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  },
)
SingleSlip.displayName = 'SingleSlip'

export default function BlackjackModal({ isOpen, onClose, data, bsvToUsd }) {
  const { user } = useSelector(authSelector)
  const [betPlacedUser, setBetPlacedUser] = useState({})
  const intl = useIntl()
  useEffect(() => {
    if (data?.uid) fetchUserProfile(data.uid)
  }, [data?.uid])

  const fetchUserProfile = async (uid) => {
    const profile = await getProfileSetting(uid)
    if (profile) setBetPlacedUser(profile)
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20 bg-white" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={`fixed inset-0 backdrop-blur-md bg-black/90`} />
        </Transition.Child>
        <div className="fixed inset-0">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="flex flex-row items-center justify-center">
                <Dialog.Panel
                  className={
                    'transform transition-all shadow-2xl w-full relative'
                  }
                >
                  <div
                    className="absolute top-3 right-3 p-1 rounded-full hover:bg-white/5 duration-300 cursor-pointer"
                    onClick={onClose}
                  >
                    <XIcon className="w-5 h-5 text-gray-300" />
                  </div>

                  <div className="rounded-b-xl bg-white/5 pt-6 px-6 overflow-auto scroller max-h-[calc(100vh-200px)] min-w-[23rem] md:min-w-[28rem]">
                    {/* Info */}
                    <div className="space-y-3 flex flex-col items-center">
                      <div className="flex flex-row items-center justify-center text-base font-semibold font-inter">
                        <h4>{'Bet' + ' ID' + ' ' + data?.id}</h4>
                        <div className="flex flex-row items-center gap-2 ml-2 relative w-14 justify-around">
                          <div>
                            <DuplicateIcon
                              className="shrink-0 w-6 h-6 cursor-pointer"
                              onClick={() => {
                                window.navigator.clipboard.writeText(data?.id)
                                successMsg(
                                  intl.formatMessage(
                                    {
                                      id: 'success.betIdCopied',
                                      defaultMessage: `BetID ${data?.id} Successfully Copied`,
                                    },
                                    { betID: data?.id },
                                  ),
                                )
                              }}
                            />
                          </div>
                          <div>
                            <LinkChain
                              className="shrink-0 w-6 h-6 cursor-pointer"
                              onClick={() => {
                                window.navigator.clipboard.writeText(
                                  `${window.location.host}/embed?betId=${data.betID}&game=plinko`,
                                )
                                successMsg(
                                  intl.formatMessage({
                                    id: 'success.betSlipLinkCopied',
                                    defaultMessage:
                                      'Bet slip link successfully copied',
                                  }),
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-[#98A2B3] font-inter space-x-2 flex flex-row items-center justify-center">
                        <h4 className="font-normal text-base">Placed by</h4>
                        <div className="bg-white/5 rounded-2xl px-2.5 py-1 flex flex-row items-center gap-1 relative">
                          <Profile className="w-5 h-5 text-gray-300" />
                          {user ? (
                            <h4 className="font-medium text-sm capitalize">
                              {betPlacedUser.uid
                                ? betPlacedUser?.username ||
                                  betPlacedUser?.displayName
                                : ''}
                            </h4>
                          ) : (
                            <h4 className="font-medium text-sm capitalize">
                              {'Hidden'}
                            </h4>
                          )}
                        </div>
                      </div>
                      <div className="font-inter font-normal text-xs">
                        {'on' +
                          ' ' +
                          formatDate(data?.date, 'MM/DD/YYYY') +
                          ' ' +
                          formatDate(data?.date, 'hh:mm A')}
                      </div>
                    </div>

                    <div className="mt-6 ml-[2rem] w-[16rem] sm:w-[21rem] mb-2">
                      <div>
                        <SingleSlip
                          betData={data}
                          topRounded
                          bottomRounded
                          slipBorder={false}
                          isMulti={false}
                          intl={intl}
                        />
                      </div>
                    </div>

                    <div className="mt-8">
                      <div className="flex items-center justify-center relative">
                        <LilbitLogo className="w-[6.5rem] h-8" />
                      </div>
                      <div className="mt-3 mb-10 space-y-4 font-inter text-xs">
                        <div className="flex flex-row items-center justify-between">
                          <h3 className="font-semibold">Deal</h3>
                          <div className="flex flex-col space-y-1">
                            <div className="flex space-x-1 items-center">
                              {/* <span>{score(data.userHands[0].cards)}</span> */}
                              {data.hand.map((card, idx) => {
                                return <VideoPokerCard key={idx} card={card} />
                              })}
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-row items-center justify-between">
                          <h3 className="font-semibold">Draw</h3>
                          <div className="flex space-x-1 items-center">
                            {/* <span>{score(data.dealerHands[0].cards)}</span> */}
                            {data.lastHand.map((card, idx) => {
                              return <VideoPokerCard key={idx} card={card} />
                            })}
                          </div>
                        </div>

                        <div className="flex flex-row items-center justify-between">
                          <h3 className="font-semibold">Total Bet</h3>
                          <div className="flex flex-col items-end">
                            <div>
                              <span> ${bsvToUsd(data.bsvBet).toFixed(7)}</span>
                            </div>
                            <div className="has-tooltip flex space-x-0.5">
                              <span className="tooltip rounded shadow-lg p-1 bg-gray-700 text-gray-100 -mt-6">
                                $
                                {Number(
                                  bsvToUsd(data.bsvBet).toFixed(5) || 0.0,
                                )}
                              </span>
                              <Btcsv className="w-4 h-4" />
                              <span>{data.bsvBet.toFixed(7)}</span>
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-row items-center justify-between">
                          <h3 className="font-semibold">Payout</h3>

                          <div className="flex flex-col items-end">
                            <div>
                              <span>
                                {' '}
                                ${' '}
                                {Number(
                                  bsvToUsd(data.earnBsv).toFixed(7) || 0.0,
                                )}
                              </span>
                            </div>
                            <div className="has-tooltip flex space-x-0.5">
                              <span className="tooltip rounded shadow-lg p-1 bg-gray-700 text-gray-100 -mt-6">
                                ${' '}
                                {Number(
                                  bsvToUsd(data.earnBsv).toFixed(5) || 0.0,
                                )}
                              </span>
                              <Btcsv className="w-4 h-4" />
                              <span>
                                {' '}
                                {data.earnBsv > 0 ? data.earnBsv.toFixed(7) : 0}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </div>
        +{' '}
      </Dialog>
    </Transition>
  )
}
