import useExchangeRates from '@/hooks/useExchangeRates'
import { AgGridReact } from 'ag-grid-react'
import React, { useCallback, useMemo } from 'react'
import {
  calculateTimeMultiplier,
  dateFormatter,
  getAmountRenderer,
  getExpireyDate,
  getRowId,
  UserRenderer,
} from './components'

const getTimeMultiplierFormatter = (eventsData) => (params) => {
  const expiryDate = getExpireyDate(
    eventsData[params.data.market.id]?.expiryDate,
  )
  return calculateTimeMultiplier(params.value, expiryDate)
}

const PredictionTable = ({ betsData, onBetIdPress, user, isHome }) => {
  const { bsvToUsd } = useExchangeRates()

  const gridStyle = { height: '100%', width: '100%', boxSizing: 'border-box' }

  const onCellClicked = useCallback(
    ({ data }) => {
      const expiryDate = getExpireyDate(
        betsData.events[data.market.id]?.expiryDate,
      )
      const title = betsData.events[data.market.id]?.title
      onBetIdPress(data, expiryDate, title)
    },
    [betsData, onBetIdPress],
  )

  const colDefs = useMemo(() => {
    const baseColumns = [
      {
        field: 'answer',
        cellStyle: ({ value }) => {
          return {
            color: value === 'yes' ? '#00FF00' : '#FF0000',
            justifyContent: 'left',
            fontWeight: 700,
          }
        },
      },
      {
        field: 'amount',
        headerName: 'Amount',
        cellRenderer: getAmountRenderer(bsvToUsd),
        tooltipValueGetter: (params) => params.value,
      },
      {
        field: 'createdAt',
        headerName: 'Time Multiplier',
        valueFormatter: getTimeMultiplierFormatter(betsData.events),
        width: 140,
      },
    ]

    const additionalColumns = [
      {
        field: 'createdAt',
        headerName: 'Date',
        valueFormatter: dateFormatter,
        cellDataType: ['dateString'],
        width: 140,
      },
    ]

    if (!user) {
      additionalColumns.push({
        field: 'user',
        headerName: 'User',
        cellRenderer: UserRenderer,
      })
    }

    return [...additionalColumns, ...baseColumns]
  }, [bsvToUsd, betsData, user])

  const getRowHeight = useCallback((params) => params.data.rowHeight, [])

  return (
    <div
      className={`h-[500px] pb-[0.5] mx-auto my-8 ${
        isHome ? 'w-full' : 'w-[95%] 2xl:w-[97%]'
      }`}
    >
      <div style={gridStyle} className="ag-theme-quartz-dark">
        <AgGridReact
          rowData={betsData.bets}
          columnDefs={colDefs}
          getRowId={getRowId}
          onRowClicked={onCellClicked}
          defaultColDef={{
            headerClass: 'text-center',
            cellStyle: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
            },
          }}
          getRowHeight={getRowHeight}
          rowHeight={55}
          animateRows={true}
          overlayLoadingTemplate='<span class="plinko-loader"></span>'
          overlayNoRowsTemplate='<span style="padding: 10px; border: 2px solid #444;">No Results Found</span>'
          onFirstDataRendered={(params) => {
            params.api.sizeColumnsToFit()
          }}
        />
      </div>
    </div>
  )
}

export default PredictionTable
