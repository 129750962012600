import Clubs from '@/components/pages-partials/video-poker/icons/clubs'
import Diamonds from '@/components/pages-partials/video-poker/icons/diamonds'
import Hearts from '@/components/pages-partials/video-poker/icons/hearts'
import Spades from '@/components/pages-partials/video-poker/icons/spades'

export const suitImageMap = {
  H: <Hearts className="w-4 h-4" />,
  D: <Diamonds className="w-4 h-4" />,
  S: <Spades className="w-4 h-4" />,
  C: <Clubs className="w-4 h-4" />,
}
