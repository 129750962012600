import React from 'react'

const Diamonds = (props) => {
  return (
    <svg viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>diamonds</title>
      <path
        d="M75 144c-14.85-24.445-30.475-48.204-50-68.5C44.525 55.205 60.15 31.446 75 7c14.851 24.446 30.475 48.205 50 68.5-19.525 20.296-35.149 44.055-50 68.5z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Diamonds
