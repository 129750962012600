import { refreshAuthTokenHandler } from '@/firebase/utils'
import { Chip01, Chip025, Chip5, Chip50 } from '@/icons/index'
import { handleWarningModal } from '@/redux/slices/wallet'
import { errorMsg } from '@/utils/toast'

const chipsClass = 'w-8 h-8 md:w-11 md:h-11'
export const coinValues = {
  '0.10': <Chip01 className={chipsClass} />,
  0.25: <Chip025 className={chipsClass} />,
  '5.00': <Chip5 className={chipsClass} />,
  '50.00': <Chip50 className={chipsClass} />,
}

export const FACE_VALUES = {
  a: 1,
  J: 10,
  Q: 10,
  K: 10,
  A: 11,
}

export const getNumericalValue = (card) => {
  if (FACE_VALUES[card.value]) return FACE_VALUES[card.value]
  return parseInt(card.value)
}

export const findMinNumbersForSum = (target, numbers = [0.1, 0.25, 5, 50]) => {
  const factor = 100
  const intTarget = Math.round(target * factor)
  const intNumbers = numbers.map((n) => Math.round(n * factor))

  const { dp, back } = computeMinNumbersDP(intTarget, intNumbers)

  if (dp[intTarget] === Infinity) {
    return null
  }

  return backtrackSolution(intTarget, intNumbers, back, factor)
}

const computeMinNumbersDP = (target, numbers) => {
  const dp = Array(target + 1).fill(Infinity)
  const back = Array(target + 1).fill(-1)
  dp[0] = 0

  for (let i = 0; i <= target; i++) {
    for (let j = 0; j < numbers.length; j++) {
      if (numbers[j] <= i && dp[i - numbers[j]] + 1 < dp[i]) {
        dp[i] = dp[i - numbers[j]] + 1
        back[i] = j
      }
    }
  }

  return { dp, back }
}

const backtrackSolution = (target, numbers, back, factor) => {
  const solution = {}
  let current = target

  while (current > 0) {
    const number = numbers[back[current]]
    const originalNumber = (number / factor).toFixed(2)
    solution[originalNumber] = (solution[originalNumber] || 0) + 1
    current -= number
  }

  return solution
}

export const errorHandler = async (
  gameInfo,
  user,
  runAgain,
  dispatch,
  resetHand,
  isSecondTime,
) => {
  switch (gameInfo.code) {
    case 'PAYMENT_ERROR':
    case 'AUTH_ERROR': {
      const tokenAccess = await refreshAuthTokenHandler(user)
      if (!isSecondTime) {
        return runAgain(tokenAccess, true)
      } else {
        errorMsg(gameInfo.message)
        setTimeout(() => {
          resetHand()
        }, 500)
        break
      }
    }
    case 'RATE_LIMIT': {
      errorMsg('Too many requests, please try again later.')
      break
    }
    default: {
      if (gameInfo?.limit) {
        dispatch(
          handleWarningModal({
            warningModal: true,
            limitLink: gameInfo.limit,
          }),
        )
      } else {
        errorMsg(
          gameInfo?.message ||
            'We are currently undergoing maintenance on our servers.',
        )
      }
      break
    }
  }
}

const results = {
  bust: 1,
  win: 2,
  lose: 3,
  push: 4,
  blackjack: 5,
}

export const toResultString = (resultValue) => {
  for (const key in results) {
    if (results[key] === resultValue) return key
  }
}
