import { resultColors } from '@/components/pages-partials/video-poker/lib/poker'
import { BsvIcon } from '@/icons/index'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ModuleRegistry } from '@ag-grid-community/core'
import { AgGridReact } from 'ag-grid-react'
import React, { useMemo } from 'react'

ModuleRegistry.registerModules([ClientSideRowModelModule])

const HistoryTable = ({ allBets, bsvToUsd, onIdPress, isHome }) => {
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])

  const GameDate = ({ data }) => {
    return (
      <div className="text-sm font-medium capitalize flex gap-x-5 w-[100%]">
        <div className="p-2">
          <span>{data?.date}</span>
        </div>
      </div>
    )
  }

  const BetIdRenderer = ({ data }) => {
    return (
      <div className="text-sm font-medium  cursor-pointer" title={data?.id}>
        {data?.id}
      </div>
    )
  }

  const PayoutRenderer = ({ data }) => {
    const { earnBsv } = data
    return (
      <div
        className={`text-sm font-medium space-y-2 text-right ${
          earnBsv > 0 ? 'text-yellow-600' : 'text-gray-600'
        }`}
      >
        <div className="flex gap-x-2 items-center">
          <BsvIcon className="w-4 h-4" />
          {earnBsv > 0 ? earnBsv.toFixed(5) : 0}
        </div>
        <div>$ {earnBsv > 0 ? Number(bsvToUsd(earnBsv)).toFixed(5) : 0}</div>
      </div>
    )
  }

  const BetRenderer = ({ data }) => {
    const { bsvBet } = data
    return (
      <div className="text-sm font-medium space-y-2 cursor-pointer text-right">
        <div className="flex gap-x-2 items-center">
          <BsvIcon className="w-4 h-4" />
          {bsvBet.toFixed(5)}
        </div>
        <div>$ {Number(bsvToUsd(bsvBet) || 0.0).toFixed(5)}</div>
      </div>
    )
  }

  const ResultRenderer = ({ data }) => {
    const { winningHand } = data
    return (
      <div className="text-sm font-medium cursor-pointer flex flex-col space-y-2">
        <span
          className={`${resultColors[winningHand]} rounded-2xl px-2 py-0.5`}
        >
          {winningHand}
        </span>
      </div>
    )
  }

  const getRowId = useMemo(() => {
    return (params) => params.data.sendTx
  }, [])

  const onCellClicked = ({ data }) => {
    onIdPress(data)
  }

  const casinoColumnDefs = [
    {
      headerName: 'Bet ID',
      field: 'betID',
      cellRenderer: BetIdRenderer,
      minWidth: 150,
      onCellClicked,
    },
    {
      headerName: 'Bet',
      field: 'betPrice',
      cellRenderer: BetRenderer,
      minWidth: 150,
      onCellClicked,
    },
    {
      headerName: 'Payouts',
      field: 'payouts',
      cellRenderer: PayoutRenderer,
      minWidth: 150,
      onCellClicked,
    },
    {
      headerName: 'Time(UTC)',
      field: 'date',
      cellRenderer: GameDate,
      minWidth: 160,
      onCellClicked,
    },
    {
      headerName: 'Result',
      field: 'result',
      cellRenderer: ResultRenderer,
      minWidth: 160,
      onCellClicked,
    },
  ]

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      sortable: false,
    }
  }, [])

  return (
    <div
      className={`h-[500px] pb-[0.5] mx-auto ${
        isHome ? 'w-full' : 'w-[95%] 2xl:w-[97%]'
      } my-8 bg-black/70 rounded-md`}
      id="grid-wrapper"
    >
      <div style={gridStyle} className="ag-theme-quartz-dark">
        <AgGridReact
          rowData={allBets}
          columnDefs={casinoColumnDefs}
          defaultColDef={defaultColDef}
          animateRows
          getRowId={getRowId}
          rowHeight={70}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; ">No Results Found</span>'
          }
          overlayLoadingTemplate='<span class="plinko-loader"></span>'
          onFirstDataRendered={(params) => params.api.sizeColumnsToFit()}
        />
      </div>
    </div>
  )
}

export default HistoryTable
