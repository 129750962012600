import React from 'react'

const Clubs = (props) => {
  return (
    <svg viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>clubs</title>
      <path
        d="M74.989 6c-28.364.617-41.083 32.588-20.076 57.415 3.046 3.621 3.36 5.7-2.22 2.22-14.142-9.467-41.26-3.243-42.65 25.703-1.715 35.666 48.063 45.943 57.41 8.018.493-4.002 3.74-3.91 2.958 1.77C69.559 116.54 64.75 130.948 58.14 145h33.72c-6.61-14.05-11.418-28.459-12.27-43.875-.784-5.68 2.464-5.77 2.958-1.77 9.346 37.926 59.124 27.65 57.409-8.017-1.389-28.946-28.508-35.17-42.65-25.702-5.58 3.478-5.266 1.4-2.22-2.221C116.095 38.588 103.377 6.617 75.013 6h-.03.007z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Clubs
