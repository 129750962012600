import React from 'react'

const Hearts = (props) => {
  return (
    <svg viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>hearts</title>
      <path
        d="M75 145s-13.596-22.328-31.935-48.58c-12.27-17.569-22.078-36.591-22.99-52.976C19.03 24.726 28.93 5.949 46.855 5.039 64.769 4.129 72.04 19.47 75 32.119c2.96-12.649 10.231-27.99 28.145-27.08 17.925.91 27.826 19.687 26.78 38.405-.912 16.385-10.72 35.407-22.99 52.975C88.595 122.672 75 145 75 145z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Hearts
