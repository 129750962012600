import React from 'react'

const Spades = (props) => {
  return (
    <svg viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>spades</title>
      <path
        d="M75 7C48.658 44.553 20.472 66.163 20.003 95.46c-.157 9.487 4.975 25.612 21.047 28.698 10.427 1.993 24.783-6.443 25.083-23.582-.046-3.413 3.615-3.324 3.58 1.352-.49 13.519-4.772 29.26-11.967 42.072h34.508c-7.195-12.813-11.476-28.553-11.967-42.072-.035-4.676 3.626-4.765 3.58-1.352.3 17.139 14.656 25.575 25.083 23.582 16.072-3.086 21.204-19.21 21.047-28.698C129.528 66.163 101.342 44.553 75 7z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Spades
