const FACE_VALUES = {
  a: 1,
  J: 10,
  Q: 10,
  K: 10,
  A: 11,
}

const results = {
  bust: 1,
  win: 2,
  lose: 3,
  push: 4,
  blackjack: 5,
}

const score = (cardsToTotal, getHighTotal = false) => {
  if (!cardsToTotal) return
  let cards = cardsToTotal.map(makeAcesLow)
  let lowTotal = cards.reduce(sumCards, 0)
  let highTotal = makeOneAceHigh(cards).reduce(sumCards, 0)
  if (highTotal <= 21 || getHighTotal) return highTotal
  return lowTotal
}

const sumCards = (a, b) => {
  return a + getNumericalValue(b)
}

const getNumericalValue = (card) => {
  if (FACE_VALUES[card.value]) return FACE_VALUES[card.value]
  return parseInt(card.value)
}

const makeAcesLow = (cardToModify) => {
  let card = Object.assign({}, cardToModify)
  if (card.value === 'A') card.value = 'a'
  return card
}

const makeOneAceHigh = (cardsToModify) => {
  let cards = cardsToModify.slice()
  for (let i = 0; i < cards.length; i++) {
    if (cards[i].value === 'a') {
      cards[i].value = 'A'
      break
    }
  }
  return cards
}

export { score, results }
