import React from 'react'
import { suitImageMap } from './card'

const BlackjackCard = ({ card }) => {
  const { value, suit } = card
  const textColor = 'HD'.includes(suit) ? 'text-red-500' : 'text-black'
  return (
    <div className="relative w-10 h-14">
      <div
        className={`absolute ${textColor} top-0 left-0 w-full h-full flex flex-col justify-between rounded-sm shadow-card-shadow overflow-hidden bg-white`}
      >
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          {suitImageMap[suit]}
        </div>
        <div className="absolute left-1 top-1 text-md">{value}</div>
        <div className="absolute right-1 bottom-1 text-md">{value}</div>
      </div>
    </div>
  )
}

export default BlackjackCard
