import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { firebaseGetUsername } from '@/firebase/utils'
import PredictionModalData from './predictionModalData'
import { XIcon } from '@heroicons/react/outline'

export default function PredictionModal({
  isOpen,
  onClose,
  data,
  expiryDate,
  title,
}) {
  const [username, setUsername] = useState('')

  useEffect(() => {
    if (data) {
      firebaseGetUsername(data.user).then(setUsername)
    }
  }, [data])

  if (!data) return null

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40 bg-white " onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={`fixed inset-0 backdrop-blur-md bg-black/90`} />
        </Transition.Child>
        <div className="fixed inset-0">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="flex flex-row items-center justify-center">
                <Dialog.Panel
                  className={
                    'transform transition-all shadow-2xl w-full relative'
                  }
                >
                  <div
                    className="absolute top-3 right-3 p-1 rounded-full hover:bg-white/5 duration-300 cursor-pointer z-30"
                    onClick={onClose}
                  >
                    <XIcon className="w-5 h-5 text-gray-300" />
                  </div>

                  <PredictionModalData
                    data={data}
                    title={title}
                    expiryDate={expiryDate}
                    username={username}
                  />
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
